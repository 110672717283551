import Typography from "@/components/Typography";

import { SummaryCardProps } from "./types";

const SummaryCard = ({ value, date, title, money }: SummaryCardProps) => {
  return (
    <div className="flex h-full w-full flex-col gap-6 rounded-3xl bg-white px-6 py-8">
      <div className="flex w-full justify-between">
        <Typography variant="overline" color="grey600" className="uppercase">
          {title}
        </Typography>
      </div>
      <div>
        {money && <span className="font-xl text-grey950">R$</span>}
        <Typography variant="h2" color="grey950">
          {value}
        </Typography>
      </div>

      {date && (
        <Typography variant="overline" color="grey600" className="uppercase">
          {date}
        </Typography>
      )}
    </div>
  );
};

export default SummaryCard;
