import { createColumnHelper } from "@tanstack/react-table";

import SimpleButton from "@/components/Buttons/SimpleButton";
import Pagination from "@/components/Pagination";
import Table from "@/components/Table";
import Typography from "@/components/Typography";

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("docName", {
    header: () => (
      <Typography variant="overline" color="white" className="uppercase">
        título
      </Typography>
    ),
    cell: (info: any) => (
      <div className="flex w-full justify-start px-6">
        <Typography variant="btnLarge" color="black">
          {info.row.original.title}
        </Typography>
      </div>
    ),
  }),
  columnHelper.accessor("plant", {
    header: () => (
      <Typography variant="overline" color="white" className="uppercase">
        usina
      </Typography>
    ),
    cell: (info: any) => (
      <div className="flex w-full justify-start px-6">
        <Typography variant="btnLarge" color="black">
          {info.row.original.power_plant_name}
        </Typography>
      </div>
    ),
  }),
  columnHelper.accessor("date", {
    header: () => (
      <Typography variant="overline" color="white" className="uppercase">
        data
      </Typography>
    ),
  }),
  columnHelper.accessor("download", {
    header: () => "",
    cell: (info: any) => {
      if (info.row.original.pdf_url) {
        return (
          <div className="flex w-full justify-center">
            <div className="w-40">
              <a href={info.row.original.pdf_url} target="_blank" rel="noreferrer">
                <SimpleButton variant="simple" label="Baixar" />
              </a>
            </div>
          </div>
        );
      }
    },
  }),
];

const DocsTableSection = ({ events, page, setPage, maxPages, getData }) => {
  return (
    <section className="flex h-fit flex-col gap-5 pt-8">
      <div className="flex h-fit items-center justify-between">
        <Typography variant="h4" color="black">
          Documentos importantes
        </Typography>
        <div />
      </div>
      <div>
        <Table data={events} columns={columns} onClickRow={null} />
        <Pagination page={page} setPage={setPage} maxPages={maxPages} getData={getData} />
      </div>
    </section>
  );
};

export default DocsTableSection;
