import QuotasCard from "@/components/Cards/Investor/QuotasCard";
import { QuotasCardProps } from "@/components/Cards/Investor/QuotasCard/types";
import Pagination from "@/components/Pagination";
import Typography from "@/components/Typography";

const QuotasSection = ({
  shares,
  page,
  setPage,
  maxPages,
  getData,
}: {
  shares: QuotasCardProps[];
  page: number;
  setPage: (page: number) => void;
  maxPages: number;
  getData: (page: number) => void;
}) => {
  return (
    <section className="flex h-fit flex-col gap-5 pt-8">
      <div className="flex h-fit items-center justify-between">
        <Typography variant="h4" color="black">
          Minhas cotas
        </Typography>
      </div>
      {shares.map((props, index) => {
        return <QuotasCard key={index} {...props} />;
      })}
      <Pagination page={page} setPage={setPage} maxPages={maxPages} getData={getData} />
    </section>
  );
};

export default QuotasSection;
