import { Dialog, Transition } from "@headlessui/react";
import { createColumnHelper } from "@tanstack/react-table";
import React, { Dispatch, Fragment, SetStateAction } from "react";

import SimpleButton from "../Buttons/SimpleButton";
import Dropdown from "../Dropdown";
import Table from "../Table";

// Payment modal only
export default function ExpenseStatusModal({
  isOpen,
  setIsOpen,
  info,
  yearsOptions,
  changePage,
  changeFilters,
  hasMoreLogs,
  logsPage,
  filters,
  completMonth,
  statusOptions,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  info: any;
  logsPage: number;
  changePage: any;
  hasMoreLogs: boolean;
  yearsOptions: any;
  changeFilters: any;
  filters: any;
  completMonth: any;
  statusOptions: any;
}) {
  const columnHelper = createColumnHelper();

  function closeModal() {
    setIsOpen(false);
  }

  const translateStatus = {
    processing: "Em processamento",
    failed: "Falhou",
    succeded: "Bem sucedido",
    empty: "Nenhuma conta criada",
    ended: "Finalizado",
    skipped: "Não criado",
    created: "Criado",
  };

  const columns = [
    columnHelper.accessor("updated_at", {
      header: () => "Data",
      cell: (info: any) => {
        if (info.row.original.updated_at) {
          const date = new Date(info.row.original.updated_at);
          return (
            <p className="text-center">{`${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()}`}</p>
          );
        } else {
          ("");
        }
      },
    }),
    columnHelper.accessor("status", {
      header: () => "Status",
      cell: (info: any) => (
        <p className="text-start">{translateStatus[info.row.original.status]}</p>
      ),
    }),
    columnHelper.accessor("description", {
      header: () => "Descrição",
      cell: (info: any) => <p className="p-2 text-center">{info.row.original.description}</p>,
    }),
    columnHelper.accessor("expenses_created", {
      header: () => "Contas criadas",
      cell: (info: any) => info.row.original.expenses_created || "-",
    }),
    columnHelper.accessor("reference_month", {
      header: () => "Mês de referência",
      cell: (info: any) => {
        if (info.row.original.reference_month) {
          return `${info.row.original.reference_month.split("-")[1]}/${
            info.row.original.reference_month.split("-")[0]
          }`;
        } else {
          ("-");
        }
      },
    }),
  ];

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-50"
            leave="ease-in duration-200"
            leaveFrom="opacity-50"
            leaveTo="opacity-0"
          >
            <div className="bg-opacity-/50 fixed inset-0 bg-black" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="max-h-[900px] min-h-[500px] w-full max-w-[900px] overflow-y-scroll rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div>
                    <Dialog.Title
                      as="h3"
                      className="mb-8 text-lg font-medium leading-6 text-gray-900"
                    >
                      Logs de criação de contas
                    </Dialog.Title>
                    <div className="flex gap-2">
                      <Dropdown
                        options={yearsOptions}
                        onSelect={(value) => {
                          changeFilters({ year: value });
                        }}
                        defaultValue={filters.year}
                      />
                      <Dropdown
                        options={completMonth}
                        onSelect={(value) => {
                          changeFilters({ month: value });
                        }}
                        defaultValue={filters.month}
                      />
                      <Dropdown
                        options={statusOptions}
                        onSelect={(value) => {
                          if (value == "todos") {
                            changeFilters({ status: null });
                          } else {
                            changeFilters({ status: value });
                          }
                        }}
                        defaultValue={"todos"}
                      />
                    </div>
                  </div>

                  {info.length > 0 ? (
                    <>
                      <Table data={info} columns={columns} onClickRow={null} />
                      {hasMoreLogs && (
                        <SimpleButton
                          label="Carregar mais"
                          onClick={() => {
                            changePage(logsPage + 1);
                          }}
                          variant="simple"
                        />
                      )}
                    </>
                  ) : (
                    <h2>
                      {" "}
                      Não há registros de criação no mês indicado. Mude o filtro para buscar mais
                      registros{" "}
                    </h2>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
