export default function moneyFormatter(value: number) {
  if (value) {
    return value.toLocaleString("pt-BR", {
      useGrouping: true,
      style: "currency",
      currency: "BRL",
    });
  } else {
    return "R$ 0,00";
  }
}

export function moneyFormatterWithoutCurrency(value: number) {
  return moneyFormatter(value).replace("R$", "").replace(/\s/g, "");
}

export function moneyCentsFormatter(value: number) {
  if (value) {
    return (value / 100).toLocaleString("pt-BR", {
      useGrouping: true,
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    return "R$ 0,00";
  }
}

export function moneyCentsFormatterWithoutCurrency(value: number) {
  return moneyCentsFormatter(value).replace("R$", "").replace(/\s/g, "");
}
