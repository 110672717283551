import { useMemo } from "react";
import Chart from "react-apexcharts";

import Dropdown from "@/components/Dropdown";
import Typography from "@/components/Typography";

const barAxis = {
  labels: {
    showDuplicates: false,
    style: {
      colors: "#818A8F",
    },
    formatter: function (value: number): string {
      return value.toFixed(2);
    },
  },
  title: {
    style: {
      fontWeight: 700,
    },
  },
};

const lineAxis = {
  opposite: true,
  title: {
    text: "Dividend Yield (%)",
  },
  labels: {
    formatter: (value) => `${value.toFixed(2)}%`,
  },
};

const baseOptions = {
  annotations: {},
  fontColor: "#818A8F",
  chart: {
    animations: {
      enabled: false,
    },
    background: "#fff",
    fontFamily: "Inter",
    height: 250,
    id: "uRSqT",
    stacked: false,
    toolbar: {
      show: false,
    },
    width: 400,
    zoom: {
      allowMouseWheelZoom: true,
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 6,
      borderRadiusApplication: "end",
      borderRadiusWhenStacked: "last",
      hideZeroBarsWhenGrouped: false,
      isDumbbell: false,
      isFunnel: false,
      isFunnel3d: true,
      colors: {
        backgroundBarColors: ["#f1f2f3"],
        backgroundBarOpacity: 0,
      },
      dataLabels: {
        total: {
          enabled: false,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#373d3f",
            fontSize: "12px",
            fontWeight: 600,
          },
        },
      },
    },
    line: {
      stroke: {
        curve: "straight",
      },
    },
  },
  colors: ["#5EC874", "#466560", "#748C88", "#7BB4AA", "#235950"],
  dataLabels: {
    enabled: false,
    style: {
      fontWeight: 700,
    },
    background: {
      dropShadow: {},
    },
  },
  grid: {
    padding: {
      right: 25,
      left: 15,
    },
  },
  legend: {
    fontSize: 14,
    offsetY: 0,
    markers: {
      size: 7,
      strokeWidth: 4,
      shape: "line",
    },
    itemMargin: {
      vertical: 0,
    },
    labels: {
      colors: "#818A8F",
    },
  },
  markers: {
    size: 4,
  },
  tooltip: {
    shared: false,
    hideEmptySeries: false,
    intersect: true,
  },
  xaxis: {
    labels: {
      trim: true,
      style: {
        colors: "#818A8F",
      },
    },
    group: {
      groups: [],
      style: {
        colors: [],
        fontSize: "12px",
        fontWeight: 400,
        cssClass: "",
      },
    },
    tickPlacement: "between",
    title: {
      style: {
        fontWeight: 700,
      },
    },
    tooltip: {
      enabled: false,
    },
    categories: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
  },
  theme: {
    palette: "palette2",
  },
};

const DividendsGraphSection = ({ data, years, currentYear, setCurrentYear }) => {
  const options: any = useMemo(() => {
    const options = {
      ...baseOptions,
      // All the dividends data series need to have the same seriesName so they share the same axis
      yaxis: [
        { ...barAxis, seriesName: data[0].name },
        ...Array.from({ length: data.length - 2 }, () => ({ seriesName: data[0].name })),
        lineAxis,
      ],
      stroke: {
        // Only the line series show have a border width
        width: Array.from({ length: data.length }, (_, index) =>
          index === data.length - 1 ? 4 : 0,
        ),
      },
    };

    return options;
  }, [data]);

  return (
    <section className="flex h-fit flex-col gap-5 pt-8">
      <div className="flex h-fit items-center justify-between">
        <Typography variant="h4" color="black">
          Dividendos Mês a Mês
        </Typography>
        <Dropdown options={years} onSelect={(e) => setCurrentYear(e)} defaultValue={currentYear} />
      </div>
      <div className="h-[290px] w-full rounded-3xl">
        <Chart options={options} series={data} type="bar" width="100%" height="290px" />
      </div>
    </section>
  );
};

export default DividendsGraphSection;
