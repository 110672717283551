import styled from "styled-components";

interface props {
  percentage: number;
}

export const GraphPercentage = styled.div<props>`
  position: absolute;
  max-width: 100%;
  width: ${(props) => `${props.percentage}%`};
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.primary400};
  height: 4rem;
`;

export const FirstDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.primary500};
  height: 100%;
  width: 99%;
  padding: 4rem 8rem;
  margin: 0.5rem 0.5rem 0rem 0.5rem;
  border-radius: 20px 20px 0px 0px;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const ButtonDiv = styled.div`
  max-width: 15rem;
`;

export const SecondDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.primary400};
  height: 100%;
  width: 99%;
  border-radius: 20px 20px 0px 0px;
  padding: 6rem 8rem;
  margin: -1rem 0.5rem 0rem 0.5rem;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const ThirdDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.primary300};
  margin: -1.5rem 0.5rem 0rem 0.5rem;
  width: 99%;
  border-radius: 20px 20px 0px 0px;
  padding: 4rem 8rem;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const FourthDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.primary200};
  margin: -1.5rem 0.5rem 0rem 0.5rem;
  width: 99%;
  border-radius: 20px 20px 0px 0px;
  padding: 4rem 8rem;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const FifthDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.primary100};
  margin: -1.5rem 0.5rem 0rem 0.5rem;
  width: 99%;
  border-radius: 20px 20px 20px 20px;
  padding: 4rem 8rem;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const SixthDiv = styled.div`
  background-color: #7e9692;
  margin: -2rem 0.5rem 0rem 0.5rem;
  width: 99%;
  border-radius: 20px 20px 20px 20px;
  padding: 4rem 8rem;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const StartDiv = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const BreakDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DatesSecondDiv = styled.div`
  display: flex;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 0.8rem;
  }
`;

export const Line = styled.div`
  min-height: 62px;
  min-width: 1px;
  background-color: ${({ theme }) => theme.colors.primary200};

  @media screen and (max-width: 768px) {
    min-height: 1px;
    width: 100%;
  }
`;

export const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 8rem 10rem;
  gap: 7rem;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const InformationBox = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin-top: 3rem;

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 1rem;
    margin-bottom: 2rem;
  }
`;

export const ValuesDiv = styled.div`
  display: flex;
  margin-top: 5px;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const IconDiv = styled.div`
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BarGraph = styled.div`
  margin-top: 3rem;
`;

export const Graph = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.primary500};
  height: 4rem;
  position: relative;
`;

export const GraphInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  display: flex;
  gap: 4rem;
`;

export const Observation = styled.div`
  display: flex;
  width: 30%;
`;

export const CenterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
`;

export const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
`;
