import { router } from "@inertiajs/react";
import { createColumnHelper } from "@tanstack/react-table";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiCheckCircle, BiSearchAlt } from "react-icons/bi";
import { RiArrowDropDownLine } from "react-icons/ri";

import SimpleButton from "@/components/Buttons/SimpleButton";
import FaqCardComponent from "@/components/Cards/FaqComponent";
import Dropdown from "@/components/Dropdown";
import Layout from "@/components/Layout";
import ApproveExpenseModal from "@/components/Modal/ApproveExpenseModal";
import ExpenseStatusModal from "@/components/Modal/ExpenseStatusModal";
import Table from "@/components/Table";
import Typography from "@/components/Typography";
import {
  ButtonContainer,
  FiltersContainer,
  ListSection,
  PageContainer,
} from "@/constants/listIndexStyle";
import { completMonth } from "@/utils/dateFormater";
import { moneyFormatterWithoutCurrency } from "@/utils/moneyFormat";
import { formatNumberWithCommas } from "@/utils/numberFormater";

import { SearchForm } from "../power-plants/style";

const yearsOptions: string[] = ["2022", "2023", "2024", "2025", "2026"];
const logStatusOptions: string[] = [
  "Em processamento",
  "Falhou",
  "Bem sucedido",
  "Finalizado",
  "Todos",
];
const statusOptions: string[] = ["Aprovação pendente", "Não pago", "Pago", "Todos"];
const statusTranslate = {
  "Aprovação pendente": "processing",
  Falhou: "failed",
  "Bem sucedido": "succeded",
  Finalizado: "empty",
  "": "",
  Todos: "",
};

const AccountsPage = ({ consumers }) => {
  const [filteredConsumers, setFilteredConsumers] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const totalPages = 1;
  const [loadingConsumers, setLoadingConsumers] = useState(true);
  const [loadedLogs, setLoadedLogs] = useState<any[]>([]);
  const [logsPage, setLogsPage] = useState<number>(1);
  const [hasMoreLogs, setHasMoreLogs] = useState(true);
  const columnHelper = createColumnHelper();
  const today = new Date();
  const [open, setOpen] = useState(false);
  const [approveOpen, setApproveOpen] = useState(false);
  const [selectedConsumer, setSelectedConsumer] = useState({});
  const [selectedExpenseId, setSelectedExpenseId] = useState(null);
  const [filters, setFilters] = useState({
    year: new URLSearchParams(window.location.search).get("year")
      ? String(new URLSearchParams(window.location.search).get("year"))
      : String(today.getFullYear()),
    month: new URLSearchParams(window.location.search).get("month")
      ? completMonth[Number(new URLSearchParams(window.location.search).get("month")) - 1]
      : completMonth[today.getMonth() - 1],
    search: "",
    approved_status: "",
    status: "",
  });

  const [logFilters, setLogFilters] = useState({
    year: new URLSearchParams(window.location.search).get("year")
      ? String(new URLSearchParams(window.location.search).get("year"))
      : String(today.getFullYear()),
    month: new URLSearchParams(window.location.search).get("month")
      ? completMonth[Number(new URLSearchParams(window.location.search).get("month")) - 1]
      : completMonth[today.getMonth() - 1],
    status: "",
  });

  const changeFilters = ({
    year = filters.year,
    month = filters.month,
    search = filters.search,
    approved_status = filters.approved_status,
    status = filters.status,
  }) => {
    setPage(1);
    setFilters({ year, month, search, approved_status, status });
    setLogFilters({ ...logFilters, year: year, month: month });
  };

  const changeLogFilters = ({
    year = logFilters.year,
    month = logFilters.month,
    status = logFilters.status,
  }) => {
    setPage(1);
    setLogFilters({ year, month, status });
    setFilters({ ...filters, year: year, month: month });
  };

  const showMore = () => page !== totalPages && filteredConsumers.length !== 0;

  const columns = [
    columnHelper.accessor("name", {
      header: () => "Cliente/Usina",
      cell: (info: any) => {
        if (info.row.depth === 0) {
          return info.row.original.name;
        }
        return info.row.original.power_plant_name || "-";
      },
    }),
    columnHelper.accessor("facilities_count", {
      header: () => "Quantidade UC",
      cell: (info: any) => {
        if (info.row.depth === 0) {
          return (
            <div className="flex w-full justify-center">
              <span className="rounded-md bg-gray-50 px-2 py-1">
                {info.row.original.facilities_count || "-"}
              </span>
            </div>
          );
        }
        return (
          <div className="flex w-full justify-center">
            <span className="px-2 py-1">-</span>
          </div>
        );
      },
    }),
    columnHelper.accessor("distributor_expenses_count", {
      header: () => "Quantidade CD",
      cell: (info: any) => {
        if (info.row.depth === 0) {
          return info.row.original.distributor_expenses_count || "-";
        }
        return "-";
      },
    }),
    columnHelper.accessor("price", {
      header: () => "Valor",
      cell: (info: any) => {
        if (info.row.depth === 0) {
          return info.row.original.subRows.length > 0
            ? `R$${moneyFormatterWithoutCurrency(
                info.row.original.subRows.reduce(
                  (sum: number, row: any) => sum + (parseFloat(row.price) || 0),
                  0,
                ),
              )}`
            : info.row.original.price !== "-";
        }
        return info.row.original.price !== "-"
          ? `R$${moneyFormatterWithoutCurrency(info.row.original.price)}`
          : "-";
      },
    }),
    columnHelper.accessor("discount", {
      header: () => "Desconto",
      cell: (info: any) => {
        if (info.row.depth === 0) {
          return "-";
        }
        return info.row.original.discount_percentage !== "-"
          ? `${(info.row.original.discount_percentage * 100).toFixed(2)}%`
          : "-";
      },
    }),
    columnHelper.accessor("kwh", {
      header: () => "Consumo",
      cell: (info: any) => {
        if (info.row.depth === 0) {
          return "-";
        }
        return info.row.original.kwh !== "-"
          ? `${formatNumberWithCommas(info.row.original.kwh)} kwh`
          : "-";
      },
    }),
    columnHelper.accessor("compensated_kwh", {
      header: () => "Injetado",
      cell: (info: any) => {
        if (info.row.depth === 0) {
          return "-";
        }
        return info.row.original.compensated_kwh !== "-"
          ? `${formatNumberWithCommas(info.row.original.compensated_kwh)} kwh`
          : "-";
      },
    }),
    columnHelper.accessor("status", {
      header: () => "Status",
      cell: (info: any) => {
        if (info.row.depth === 0) {
          if (info.row.original.approved_status === "pending") {
            return "Aprovação pendente";
          } else if (info.row.original.payment_status === "paid") {
            return "pago";
          } else {
            return "-";
          }
        } else {
          if (info.row.original.approved_status == "-") {
            return "-";
          } else if (
            info.row.original.approved_status == null ||
            info.row.original.approved_status == "pending"
          ) {
            return "Aprovação pendente";
          } else if (info.row.original.payment_status == "paid") {
            return "pago";
          } else {
            return "não pago";
          }
        }
      },
    }),
    columnHelper.accessor("approved_status", {
      header: () => "Enviar conta",
      cell: (info: any) => {
        if (info.row.depth === 0) {
          if (info.row.original.subRows.length > 0) {
            return (
              <div className="flex w-full justify-center">
                <RiArrowDropDownLine className="h-8 w-8" />
              </div>
            );
          }
          return "-";
        }
        if (info.row.original.approved_status == "-") {
          return "-";
        } else if (
          info.row.original.approved_status == null ||
          info.row.original.approved_status == "pending"
        ) {
          return (
            <div className="flex w-full justify-center">
              <div className="w-7/12">
                <SimpleButton
                  variant="simple"
                  label="Aprovar"
                  onClick={() => {
                    setSelectedConsumer(info.row.original);
                    setSelectedExpenseId(info.row.original.id);
                    setApproveOpen(true);
                  }}
                />
              </div>
            </div>
          );
        } else {
          return (
            <div className="flex w-full justify-center">
              <BiCheckCircle />
            </div>
          );
        }
      },
    }),
  ];

  useEffect(() => {
    let filtered = consumers.filter((consumer: any) =>
      consumer.name.toLowerCase().includes(filters.search.toLowerCase()),
    );
    if (filters.status) {
      filtered = filtered.filter((consumer: any) => consumer.payment_status == filters.status);
    }
    if (filters.approved_status) {
      filtered = filtered.filter(
        (consumer: any) => consumer.approved_status == filters.approved_status,
      );
    }

    setFilteredConsumers(filtered);
  }, [filters.search, consumers]);

  useEffect(() => {
    const monthIndex = completMonth.indexOf(filters.month) + 1;
    const validMonthIndex = monthIndex > 0 ? monthIndex : 1; // Use 1 (janeiro) se o índice for inválido

    const options = {
      page: page,
      name: filters.search,
    };
    setLoadingConsumers(true);

    router.reload({
      only: ["consumers"],
      data: {
        year: filters.year,
        month: validMonthIndex,
        search: filters.search,
        approved_status: filters.approved_status,
        status: filters.status,
        options,
      },
      onFinish: () => setLoadingConsumers(false),
    });
  }, [filters, page]);

  useEffect(() => {
    const monthIndex = completMonth.indexOf(logFilters.month) + 1;
    const validMonthIndex = monthIndex > 0 ? monthIndex : 1; // Use 1 (janeiro) se o índice for inválido
    // Use null se status for "Todos"

    router.reload({
      only: ["consumers"],
      data: {
        year: filters.year,
        month: validMonthIndex,
        search: filters.search,
        approved_status: filters.approved_status,
        status: filters.status,
      },
    });

    setLogsPage(1);

    if (open) {
      fetchLogs(validMonthIndex, statusTranslate[logFilters.status], true);
    }
  }, [logFilters]);

  const changeLogsPage = (page: number) => {
    setLogsPage(page);
  };

  const fetchLogs = (validMonthIndex: number, validStatus: string, refresh: boolean) => {
    axios
      .get(
        `/manager/accounts.json?logYear=${logFilters.year}&logMonth=${validMonthIndex}&logStatus=${validStatus}&logPage=${logsPage}`,
      )
      .then((response) => {
        if (refresh) {
          setLoadedLogs(response.data.logs);
        } else {
          setLoadedLogs([...loadedLogs, ...response.data.logs]);
        }
        setHasMoreLogs(response.data.has_more);
      });
  };

  useEffect(() => {
    if (open) {
      const monthIndex = completMonth.indexOf(logFilters.month) + 1;
      const validMonthIndex = monthIndex > 0 ? monthIndex : 1;

      fetchLogs(validMonthIndex, statusTranslate[logFilters.status], logsPage == 1);
    }
  }, [open, logsPage]);

  return (
    <Layout>
      <ExpenseStatusModal
        isOpen={open}
        setIsOpen={setOpen}
        info={loadedLogs}
        logsPage={logsPage}
        changePage={changeLogsPage}
        yearsOptions={yearsOptions}
        hasMoreLogs={hasMoreLogs}
        changeFilters={changeLogFilters}
        filters={logFilters}
        completMonth={completMonth}
        statusOptions={logStatusOptions}
      />
      <ApproveExpenseModal
        isOpen={approveOpen}
        setIsOpen={setApproveOpen}
        referenceMonth={filters}
        consumer={selectedConsumer}
        expenseId={selectedExpenseId}
      />
      <PageContainer>
        <Typography variant="h3">Contas dos Clientes</Typography>
        <ListSection className="flex-col md:flex-row">
          <Typography variant="h4">Listagem</Typography>
          <FiltersContainer className="pt-5 md:pt-0">
            {loadingConsumers && (
              <div className="my-auto h-6 w-6 animate-spin rounded-full border-4 border-solid border-black border-t-transparent pt-3" />
            )}
            <SearchForm className="search" onSubmit={(e) => e.preventDefault()}>
              <input
                type="text"
                value={filters.search}
                onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                placeholder="Buscar por cliente"
              />
              <button className="search-icon">
                <BiSearchAlt />
              </button>
            </SearchForm>
            <Dropdown
              options={yearsOptions}
              onSelect={(value) => {
                changeFilters({ year: value });
              }}
              defaultValue={filters.year}
            />
            <Dropdown
              options={completMonth}
              onSelect={(value) => {
                changeFilters({ month: value });
              }}
              defaultValue={filters.month}
            />
            <Dropdown
              options={statusOptions}
              onSelect={(value) => {
                if (value == "Aprovação pendente") {
                  changeFilters({ approved_status: "pending", status: "" });
                } else if (value == "não pago") {
                  changeFilters({ approved_status: "", status: "pending" });
                } else if (value == "pago") {
                  changeFilters({ approved_status: "", status: "paid" });
                } else {
                  changeFilters({ approved_status: "", status: "" });
                }
              }}
              defaultValue={"todos"}
            />
          </FiltersContainer>
        </ListSection>
        <Table data={filteredConsumers} columns={columns} onClickRow={null} />
        <div className="w-48">
          <button
            className="flex h-[40px] w-full cursor-pointer flex-row items-center justify-center rounded-full border-[none] bg-slate-950 p-4 text-white"
            onClick={() => {
              setOpen(true);
            }}
          >
            Histórico de criação
          </button>
        </div>
        {showMore() && (
          <ButtonContainer>
            <SimpleButton
              variant="simple"
              label=" + Carregar mais"
              onClick={() => setPage(page + 1)}
            />
          </ButtonContainer>
        )}
        <div className="pt-36">
          <FaqCardComponent variant="questionSection" />
        </div>
      </PageContainer>
    </Layout>
  );
};

export default AccountsPage;
