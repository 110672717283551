import SummaryCard from "@/components/Cards/Investor/ResumeCard";
import Dropdown from "@/components/Dropdown";
import Typography from "@/components/Typography";
import { moneyCentsFormatterWithoutCurrency } from "@/utils/moneyFormat";

const SummarySection = ({
  allShareValue,
  allShareQuantity,
  lastDividend,
  years,
  currentYear,
  setCurrentYear,
}) => {
  return (
    <section className="flex h-fit flex-col gap-5 pt-4">
      <div className="flex h-fit items-center justify-between">
        <div className="flex h-full items-center gap-4">
          <Typography variant="h4" color="black">
            Resumo geral
          </Typography>
          <div className="h-6 w-px bg-grey200" />
          <Typography variant="h6" color="secondary500" className="uppercase">
            {allShareQuantity} cotas adquiridas
          </Typography>
        </div>
        <Dropdown options={years} onSelect={(e) => setCurrentYear(e)} defaultValue={currentYear} />
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        <SummaryCard
          value={moneyCentsFormatterWithoutCurrency(allShareValue)}
          percent=""
          position="up"
          title="posição total"
          money={true}
        />
        <SummaryCard
          value={moneyCentsFormatterWithoutCurrency(lastDividend.value)}
          percent=""
          position="up"
          title="Últimos dividendos"
          date={lastDividend.date}
          money={true}
        />
        <SummaryCard
          value={lastDividend.dividend_yield + "%"}
          percent=""
          position="up"
          title="dividend yield"
          date={currentYear === "Atual" ? "nos últimos 12 meses" : `durante ${currentYear}`}
        />
      </div>
    </section>
  );
};

export default SummarySection;
