import { PiSunHorizon } from "react-icons/pi";

import Typography from "@/components/Typography";
import { moneyCentsFormatter } from "@/utils/moneyFormat";

import { QuotasCardProps } from "./types";

const QuotasCard = ({
  power_plant_name,
  quantity,
  share_value,
  dividend_accumulated,
  power_plant_status,
}: QuotasCardProps) => {
  return (
    <div className="flex w-full flex-wrap gap-4 rounded-3xl bg-white px-6 py-4 md:gap-0">
      <section className="flex items-center gap-4 border-r border-grey200 md:w-1/4">
        <div
          className={`flex h-10 min-w-10 items-center justify-center rounded-full ${
            power_plant_status == "operating" ? "bg-success300" : "bg-warning300"
          }`}
        >
          <PiSunHorizon className="text-2xl text-white" />
        </div>
        <div className="flex flex-col">
          <Typography variant="overline" color="grey500" className="uppercase">
            Usina
          </Typography>
          <Typography variant="pLarge" color="black" className="font-semibold uppercase">
            {power_plant_name}
          </Typography>
        </div>
      </section>
      <section className="flex flex-col justify-center border-r border-grey200 px-8 md:w-1/6">
        <Typography variant="overline" color="grey500" className="uppercase">
          qtd de cotas
        </Typography>
        <Typography variant="pLarge" color="black" className="font-semibold uppercase">
          {quantity}
        </Typography>
      </section>
      <section className="flex flex-col border-r border-grey200 px-8 md:w-1/6">
        <Typography variant="overline" color="grey500" className="uppercase">
          posição
        </Typography>
        <Typography variant="pLarge" color="black" className="font-semibold uppercase">
          {moneyCentsFormatter(share_value)}
        </Typography>
      </section>
      <section className="flex flex-col justify-center border-r border-grey200 px-8 md:w-1/4">
        <Typography variant="overline" color="grey500" className="uppercase">
          rentabilidade c/ proventos
        </Typography>
        <Typography variant="pLarge" color="black" className="font-semibold uppercase">
          {moneyCentsFormatter(dividend_accumulated)}
        </Typography>
      </section>
      <section className="flex flex-col justify-center px-8 md:w-1/6">
        <Typography variant="overline" color="grey500" className="uppercase">
          Status
        </Typography>
        <Typography
          variant="pLarge"
          color={power_plant_status == "operating" ? "success300" : "warning300"}
          className="whitespace-nowrap font-semibold uppercase"
        >
          {power_plant_status == "operating" ? "operando " : "em construção"}
        </Typography>
      </section>
    </div>
  );
};

export default QuotasCard;
