import { Menu, Transition } from "@headlessui/react";
import { router, usePage } from "@inertiajs/react";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { BsEnvelopeOpen, BsHouse, BsPeople, BsPerson } from "react-icons/bs";
import { CiLogout } from "react-icons/ci";
import { MdKeyboardArrowDown } from "react-icons/md";
import { RiBillLine } from "react-icons/ri";
import { RxHamburgerMenu } from "react-icons/rx";
import { TbSunrise } from "react-icons/tb";

import Avatar from "@/components/Avatar";
import Logo from "@/components/Logo";
import NavbarLink from "@/components/NavBar/NavbarLink";
import { nameFormater } from "@/utils/nameFormater";

import CirclesSmallComponent from "../Circles/CirclesSmallComponent";
import Typography from "../Typography";
import {
  Align,
  Bar,
  Header,
  HeaderCenter,
  LogOutButton,
  LogOutButtonDiv,
  Pages,
  SideBar,
  User,
} from "./styles";
import { NavBarProps } from "./types";

const managerLinks = [
  {
    pathTo: "/manager/home",
    name: "Home",
    icon: BsHouse,
  },
  {
    pathTo: "/manager/consumers",
    name: "Clientes",
    icon: BsPeople,
  },
  {
    pathTo: "/manager/power-plants",
    name: "Usinas",
    icon: TbSunrise,
  },
  {
    pathTo: "/manager/accounts",
    name: "Contas",
    icon: BsEnvelopeOpen,
  },
];

const investorLinks = [
  {
    pathTo: "/investor/home",
    name: "Home",
    icon: BsHouse,
  },
  {
    pathTo: "/profile",
    name: "Meus dados",
    icon: BsHouse,
  },
];

const consumerLinks = [
  {
    pathTo: "/consumer/home",
    name: "Home",
    icon: BsHouse,
  },
  {
    pathTo: "/consumer/bills",
    name: "Contas",
    icon: RiBillLine,
  },
  {
    pathTo: "/profile",
    name: "Meus dados",
    icon: BsHouse,
  },
];

const NavBar = ({ user, userVariant }: NavBarProps) => {
  const { url } = usePage();

  const [whiteHeader, setWhiteHeader] = useState(false);
  const [activeBar, setActiveBar] = useState(true);

  function ActiveSidebar() {
    setActiveBar(!activeBar);
  }

  const PositionScroll = useCallback(() => {
    if (window.scrollY > 40) {
      setWhiteHeader(true);
    } else {
      setWhiteHeader(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", PositionScroll);

    return () => {
      window.removeEventListener("scroll", PositionScroll);
    };
  }, [PositionScroll]);

  return (
    <HeaderCenter>
      <Header $background={whiteHeader}>
        <Align>
          <Logo />
          <Pages>
            {userVariant == "consumer" ? (
              <>
                {consumerLinks.map((link) => (
                  <NavbarLink
                    key={link.pathTo}
                    pathTo={link.pathTo}
                    actualPath={url}
                    name={link.name}
                    icon={link.icon}
                  />
                ))}
              </>
            ) : userVariant == "investor" ? (
              <>
                {investorLinks.map((link) => (
                  <NavbarLink
                    key={link.pathTo}
                    pathTo={link.pathTo}
                    actualPath={url}
                    name={link.name}
                    icon={link.icon}
                  />
                ))}
              </>
            ) : userVariant == "admin" ? (
              <NavbarLink
                pathTo="/manager/consumers"
                actualPath={url}
                name="Clientes"
                icon={BsPeople}
              />
            ) : (
              userVariant == "manager" && (
                <>
                  {managerLinks.map((link) => (
                    <NavbarLink
                      key={link.pathTo}
                      pathTo={link.pathTo}
                      actualPath={url}
                      name={link.name}
                      icon={link.icon}
                    />
                  ))}
                </>
              )
            )}
          </Pages>
          <Typography variant="pLarge">Olá, {nameFormater(user)}</Typography>
        </Align>
        <User>
          <Avatar user={user} />
          <Menu as="div" className="relative inline-block text-left">
            <LogOutButton>
              <MdKeyboardArrowDown
                style={{
                  padding: 0,
                  fontSize: 20,
                  margin: 0,
                }}
              />
            </LogOutButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items>
                <LogOutButtonDiv>
                  {userVariant == "manager" && (
                    <Menu.Item>
                      <button
                        onClick={() => router.get("/profile")}
                        className="group flex w-full items-center gap-2 rounded-md p-2 text-sm"
                      >
                        <BsPerson color="black" size={22} />
                        <Typography variant="pLarge">Meus dados</Typography>
                      </button>
                    </Menu.Item>
                  )}
                  <Menu.Item>
                    <button
                      onClick={() => router.delete("/new_users/logout?format=html")}
                      className="group flex w-full items-center gap-2 rounded-md p-2 text-sm"
                    >
                      <CiLogout color="black" size={22} />
                      <Typography variant="pLarge">Sair</Typography>
                    </button>
                  </Menu.Item>
                </LogOutButtonDiv>
              </Menu.Items>
            </Transition>
          </Menu>
        </User>
        <SideBar $active={activeBar}>
          <div className="functions">
            <div onClick={() => router.get("/")}>
              <Logo marginClass="ml-1.5" />
            </div>
            <div className="icon-circle-white">
              <div className="icon-circle">
                <RxHamburgerMenu onClick={ActiveSidebar} />
              </div>
            </div>
          </div>
          <div className="deactivate-sidebar" onClick={ActiveSidebar} />
          <Bar $active={activeBar}>
            {userVariant == "consumer" && (
              <>
                {consumerLinks.map((link) => (
                  <div
                    key={link.pathTo}
                    className="link-page"
                    onClick={() => router.get(link.pathTo)}
                  >
                    <Typography variant="h6" color="white">
                      {link.name}
                    </Typography>
                  </div>
                ))}
              </>
            )}

            {userVariant == "manager" && (
              <>
                {managerLinks.map((link) => (
                  <div
                    key={link.pathTo}
                    className="link-page"
                    onClick={() => router.get(link.pathTo)}
                  >
                    <Typography variant="h6" color="white">
                      {link.name}
                    </Typography>
                  </div>
                ))}

                <div className="link-page" onClick={() => router.get("/profile")}>
                  <Typography variant="h6" color="white">
                    Meus dados
                  </Typography>
                </div>
              </>
            )}

            <div className="flex flex-col justify-end">
              <div
                className="link-page align-center flex flex-row gap-2"
                onClick={() => router.delete("/new_users/logout?format=html")}
              >
                <CiLogout className="mt-0.5" size="1.25rem" color="white" />
                <Typography variant="h6" color="white">
                  Sair
                </Typography>
              </div>
            </div>
            <div className="sidebar-circles">
              <CirclesSmallComponent variant="grey" />
            </div>
          </Bar>
        </SideBar>
      </Header>
    </HeaderCenter>
  );
};

export default NavBar;
