import { router } from "@inertiajs/react";
import { createColumnHelper } from "@tanstack/react-table";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { IoWarning } from "react-icons/io5";
import { TbWallet } from "react-icons/tb";

import ButtonIcon from "@/components/Buttons/SimpleButton";
import FaqCardComponent from "@/components/Cards/FaqComponent";
import Dropdown from "@/components/Dropdown";
import Footer from "@/components/Footer";
import PercentageLetterWarningModal from "@/components/Modal/PercentageLetterWarningModal";
import Table from "@/components/Table";
import Typography from "@/components/Typography";
import ExpensesGraph from "@/pages/manager/home/sections/ExpensesGraph";
import { bigNumberFormatter } from "@/utils/bigNumberFormatter";
import { dateFormat } from "@/utils/dateFormater";
import { moneyFormatterWithoutCurrency } from "@/utils/moneyFormat";
import { formatNumberWithCommas } from "@/utils/numberFormater";

import {
  BarGraph,
  BreakDiv,
  ButtonDiv,
  CenterDiv,
  ColumnDiv,
  DatesSecondDiv,
  FifthDiv,
  FiltersContainer,
  FirstDiv,
  FourthDiv,
  Graph,
  GraphInfo,
  GraphPercentage,
  IconDiv,
  InformationBox,
  Line,
  Observation,
  Page,
  SecondDiv,
  SixthDiv,
  StartDiv,
  ThirdDiv,
  Title,
  ValuesDiv,
} from "./styles";

const yearsOptions: string[] = ["2022", "2023", "2024"];
const monthOptions: string[] = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const Row = ({ value, final, mean, warning = false, setOpen }: any) => {
  return (
    <div
      className={`flex h-full w-full items-center justify-center ${
        final ? "text-primary500" : mean ? "text-primary300" : "text-primary100"
      }`}
    >
      {value}
      {warning && (
        <div onClick={() => setOpen(true)}>
          <IoWarning className="ml-1 text-warning300" />
        </div>
      )}
    </div>
  );
};

const InternalPowerPlants = ({
  expenses_info,
  consumption_info,
  consumers,
  info,
  creditBalanceInfo,
}: any) => {
  const [loadingConsumers, setLoadingConsumers] = useState(true);
  const [open, setOpen] = useState(false);
  const columnHelper = createColumnHelper();
  const today = new Date();
  const [filters, setFilters] = useState({
    year: new URLSearchParams(window.location.search).get("year")
      ? String(new URLSearchParams(window.location.search).get("year"))
      : String(today.getFullYear()),
    month: new URLSearchParams(window.location.search).get("month")
      ? monthOptions[Number(new URLSearchParams(window.location.search).get("month")) - 1]
      : monthOptions[today.getMonth()],
  });

  const changeFilters = ({ year = filters.year, month = filters.month }) => {
    setFilters({ year, month });
    setLoadingConsumers(true);
    router.reload({
      only: ["expenses_info", "consumption_info", "consumers", "creditBalanceInfo"],
      // Needs to sum + 1 because in backend it goes from 0 to 11
      data: { year: year, month: monthOptions.indexOf(month) + 1 },
      onFinish: () => setLoadingConsumers(false),
    });
  };

  const exportTable = () => {
    axios({
      url: "/manager/power_plant_report", //your url
      method: "POST",
      responseType: "blob", // important
      data: { consumers: consumers, reference_month: `${filters.month} de ${filters.year}` },
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `Consumidores - ${filters.month} de ${filters.year}.xls`); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  // Loading async consumers table
  useEffect(() => {
    setTimeout(() => {
      router.reload({
        only: ["consumers", "creditBalanceInfo"],
        // Needs to sum + 1 because in backend it goes from 0 to 11
        data: { year: filters.year, month: today.getMonth() + 1 },
        onFinish: () => setLoadingConsumers(false),
      });
    }, 10);
  }, []);

  const CustomCell = ({ row }) => {
    if (row.getCanExpand()) {
      return (
        <button
          {...{
            onClick: row.getToggleExpandedHandler(),
            style: { cursor: "pointer" },
          }}
        >
          {row.getIsExpanded() ? (
            <BiSolidDownArrow onClick={() => row.toggleExpanded()} className="h-5" />
          ) : (
            <BiSolidUpArrow onClick={() => row.toggleExpanded()} className="h-5" />
          )}
        </button>
      );
    }
  };

  const consumer_columns = [
    columnHelper.accessor("user_id", {
      header: () => "Cód",
      cell: (info: any) => info.row.original.user_id,
    }),
    columnHelper.accessor("consumer_name", {
      header: () => "Usuário",
      cell: (info: any) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
            router.get(`/manager/internal-consumer/${info.row.original.user_id}`);
          }}
        >
          {info.row.original.consumer ? info.row.original.consumer.name : info.row.original.name}
        </div>
      ),
    }),
    columnHelper.accessor("percentage", {
      header: () => "Rateio",
      cell: (info: any) => {
        const numericValue = info.row.original.consumer
          ? info.row.original.consumer.percentage
          : info.row.original.percentage;

        if (numericValue) {
          return `${numericValue}%`;
        } else {
          return "-";
        }
      },
    }),
    columnHelper.accessor("kwh", {
      header: () => "Consumo",
      cell: (info: any) =>
        info.row.original.kwh ? `${formatNumberWithCommas(info.row.original.kwh)} kwh` : "-",
    }),
    columnHelper.accessor("compensated_kwh", {
      header: () => "Injetado",
      cell: (info: any) =>
        info.row.original.compensated_kwh
          ? `${formatNumberWithCommas(info.row.original.compensated_kwh)} kwh`
          : "-",
    }),
    columnHelper.accessor("compensable_tariff", {
      header: () => "Tarifa Compensável",
      cell: (info: any) =>
        info.row.original.compensable_tariff_cents
          ? `R$${moneyFormatterWithoutCurrency(info.row.original.compensable_tariff_cents)}`
          : "-",
    }),
    columnHelper.accessor("discount", {
      header: () => "Desconto",
      cell: (info: any) => `${(info.row.original.discount_percentage * 100).toFixed(2)}%`,
    }),
    columnHelper.accessor("expensable_tariff", {
      header: () => "Tarifa de Cobrança",
      cell: (info: any) =>
        info.row.original.expensable_tariff_cents
          ? `R$${moneyFormatterWithoutCurrency(info.row.original.expensable_tariff_cents)}`
          : "-",
    }),
    columnHelper.accessor("price", {
      header: () => "Valor",
      cell: (info: any) =>
        info.row.original.price
          ? `R$${moneyFormatterWithoutCurrency(info.row.original.price)}`
          : "-",
    }),
    columnHelper.accessor("options", {
      header: "",
      cell: ({ row }) => <CustomCell row={row} />,
    }),
  ];

  const creditBalanceColumns = [
    columnHelper.accessor("name", {
      header: () => "Name",
      cell: (info: any) => (
        <Row
          value={info.getValue()}
          final={info.row.original.name == "Total"}
          warning={info.row.original.warning}
          setOpen={setOpen}
        />
      ),
    }),
    columnHelper.accessor("jan", {
      header: () => "Jan",
      cell: (info: any) => (
        <Row value={info.getValue()} final={info.row.original.name == "Total"} setOpen={setOpen} />
      ),
    }),
    columnHelper.accessor("feb", {
      header: () => "Fev",
      cell: (info: any) => (
        <Row value={info.getValue()} final={info.row.original.name == "Total"} setOpen={setOpen} />
      ),
    }),
    columnHelper.accessor("mar", {
      header: () => "Mar",
      cell: (info: any) => (
        <Row value={info.getValue()} final={info.row.original.name == "Total"} setOpen={setOpen} />
      ),
    }),
    columnHelper.accessor("apr", {
      header: () => "Abr",
      cell: (info: any) => (
        <Row value={info.getValue()} final={info.row.original.name == "Total"} setOpen={setOpen} />
      ),
    }),
    columnHelper.accessor("may", {
      header: () => "Mai",
      cell: (info: any) => (
        <Row value={info.getValue()} final={info.row.original.name == "Total"} setOpen={setOpen} />
      ),
    }),
    columnHelper.accessor("jun", {
      header: () => "Jun",
      cell: (info: any) => (
        <Row value={info.getValue()} final={info.row.original.name == "Total"} setOpen={setOpen} />
      ),
    }),
    columnHelper.accessor("jul", {
      header: () => "Jul",
      cell: (info: any) => (
        <Row value={info.getValue()} final={info.row.original.name == "Total"} setOpen={setOpen} />
      ),
    }),
    columnHelper.accessor("aug", {
      header: () => "Ago",
      cell: (info: any) => (
        <Row value={info.getValue()} final={info.row.original.name == "Total"} setOpen={setOpen} />
      ),
    }),
    columnHelper.accessor("sep", {
      header: () => "Set",
      cell: (info: any) => (
        <Row value={info.getValue()} final={info.row.original.name == "Total"} setOpen={setOpen} />
      ),
    }),
    columnHelper.accessor("oct", {
      header: () => "Out",
      cell: (info: any) => (
        <Row value={info.getValue()} final={info.row.original.name == "Total"} setOpen={setOpen} />
      ),
    }),
    columnHelper.accessor("nov", {
      header: () => "Nov",
      cell: (info: any) => (
        <Row value={info.getValue()} final={info.row.original.name == "Total"} setOpen={setOpen} />
      ),
    }),
    columnHelper.accessor("dec", {
      header: () => "Dez",
      cell: (info: any) => (
        <Row value={info.getValue()} final={info.row.original.name == "Total"} setOpen={setOpen} />
      ),
    }),
    columnHelper.accessor("mean", {
      header: () => "Média",
      cell: (info: any) => (
        <Row
          value={info.getValue()}
          final={info.row.original.name == "Total"}
          mean={true}
          setOpen={setOpen}
        />
      ),
    }),
  ];

  const date = info.created_at.split("T");
  return (
    <>
      <PercentageLetterWarningModal isOpen={open} setIsOpen={setOpen} />
      <FirstDiv>
        <ButtonDiv>
          <ButtonIcon
            onClick={() => router.get("/manager/power-plants")}
            variant="simple"
            label="Voltar para usinas"
          />
        </ButtonDiv>
        <StartDiv>
          <FiltersContainer>
            <Dropdown
              options={yearsOptions}
              onSelect={(value) => {
                changeFilters({ year: value });
              }}
              defaultValue={filters.year}
            />
            <Dropdown
              options={monthOptions}
              onSelect={(value) => {
                changeFilters({ month: value });
              }}
              defaultValue={filters.month}
            />
          </FiltersContainer>
          <div className="mt-4 flex w-full justify-between">
            <ColumnDiv>
              <Typography variant="overline" color="white">
                NOME
              </Typography>
              <Typography variant="h4" color="white">
                {info.name}
              </Typography>
            </ColumnDiv>
            <div className="col">
              <DatesSecondDiv>
                <BreakDiv>
                  <Typography variant="overline" color="white">
                    CLIENTES
                  </Typography>
                  <Typography variant="pLarge" color="white">
                    {info.energy_expenses_size}
                  </Typography>
                </BreakDiv>
                <Line />
                <BreakDiv>
                  <Typography variant="overline" color="white">
                    CAPACIDADE DE CONSUMO
                  </Typography>
                  <Typography variant="pLarge" color="white">
                    {info.installed_capacity} kwh
                  </Typography>
                </BreakDiv>
                <Line />
                <BreakDiv>
                  <Typography variant="overline" color="white">
                    DISTRIBUIDORA
                  </Typography>
                  <Typography variant="pLarge" color="white">
                    {info.distributor_name}
                  </Typography>
                </BreakDiv>
                <Line />
                <BreakDiv>
                  <Typography variant="overline" color="white">
                    DATA DE ADESÃO
                  </Typography>
                  <Typography variant="pLarge" color="white">
                    {dateFormat(date[0])}
                  </Typography>
                </BreakDiv>
              </DatesSecondDiv>
            </div>
          </div>
        </StartDiv>
      </FirstDiv>
      <SecondDiv>
        <Typography variant="h4" color="black">
          Financeiro
        </Typography>
        <InformationBox>
          <BreakDiv>
            <Typography variant="h6" color="black">
              Faturamento em {filters.month}
            </Typography>
            <ValuesDiv>
              <IconDiv>
                <TbWallet size={20} color="white" />
              </IconDiv>
              <Typography variant="h3" color="black">
                R$
                <Typography variant="h3" color="white">
                  {" "}
                  {moneyFormatterWithoutCurrency(expenses_info.this_month)}
                </Typography>
              </Typography>
            </ValuesDiv>
          </BreakDiv>
          <BreakDiv>
            <Typography variant="h6" color="black">
              Faturamento em {filters.year}
            </Typography>
            <ValuesDiv>
              <IconDiv>
                <TbWallet size={20} color="white" />
              </IconDiv>
              <Typography variant="h3" color="black">
                R$
                <Typography variant="h3" color="white">
                  {" "}
                  {moneyFormatterWithoutCurrency(expenses_info.this_year)}
                </Typography>
              </Typography>
            </ValuesDiv>
          </BreakDiv>
        </InformationBox>
      </SecondDiv>
      <ThirdDiv>
        <Typography variant="h4" color="black">
          Energia
        </Typography>
        <InformationBox>
          <BreakDiv>
            <Typography variant="h6" color="black">
              Energia injetada em {filters.month}
            </Typography>
            <ValuesDiv>
              <IconDiv>
                <AiOutlineThunderbolt size={22} color="white" />
              </IconDiv>
              <Typography variant="h3" color="white">
                {bigNumberFormatter(consumption_info.injected)}{" "}
                <Typography variant="h3" color="black">
                  kWh
                </Typography>
              </Typography>
            </ValuesDiv>
          </BreakDiv>
          <BreakDiv>
            <Typography variant="h6" color="black">
              Energia consumida em {filters.month}
            </Typography>
            <ValuesDiv>
              <IconDiv>
                <AiOutlineThunderbolt size={22} color="white" />
              </IconDiv>
              <Typography variant="h3" color="white">
                {bigNumberFormatter(consumption_info.consumed)}{" "}
                <Typography variant="h3" color="black">
                  kWh
                </Typography>
              </Typography>
            </ValuesDiv>
          </BreakDiv>
        </InformationBox>
        <BarGraph>
          <Typography variant="h6" color="black">
            Energia consumida X Energia Injetada
          </Typography>
        </BarGraph>
        <div>
          <Graph>
            <GraphPercentage
              percentage={Math.trunc(
                consumption_info.injected
                  ? consumption_info.consumed / consumption_info.injected
                  : 0 * 100,
              )}
            />
          </Graph>
          <GraphInfo>
            <BreakDiv>
              <Typography variant="overline" color="black">
                INJETADA
              </Typography>
              <Typography variant="h5" color="white">
                {bigNumberFormatter(consumption_info.injected)}{" "}
                <Typography variant="h5" color="black">
                  kWh
                </Typography>
              </Typography>
            </BreakDiv>
            <BreakDiv>
              <Typography variant="overline" color="black">
                CONSUMIDA
              </Typography>
              <Typography variant="h5" color="white">
                {bigNumberFormatter(consumption_info.consumed)}{" "}
                <Typography variant="h5" color="black">
                  kWh
                </Typography>
              </Typography>
            </BreakDiv>
          </GraphInfo>
        </div>
      </ThirdDiv>
      <FourthDiv>
        <Title>
          <Typography variant="h4" color="black">
            Contas e valores
          </Typography>
          <Observation>
            <Typography variant="pLarge" color="white">
              Dados sobre as contas pagas, atrasadas e inadimplentes e valores.
            </Typography>
          </Observation>
        </Title>

        <ExpensesGraph
          payedExpenses={info.energy_expenses_info.paid}
          lateExpenses={info.energy_expenses_info.late}
          overdueExpenses={info.energy_expenses_info.inadimplent}
          debtsTotal={info.debts_info.total}
          debtsAverage={info.debts_info.average}
        />
      </FourthDiv>
      <FifthDiv>
        <Typography variant="h4" color="black">
          Consumidores
        </Typography>
        <FiltersContainer>
          <div className="w-1/10">
            <ButtonIcon label="Exportar tabela" variant="primary" onClick={exportTable} />
          </div>
          {loadingConsumers && (
            <div className="my-auto h-6 w-6 animate-spin rounded-full border-4 border-solid border-black border-t-transparent pt-3" />
          )}
          <Dropdown
            options={yearsOptions}
            onSelect={(value) => {
              changeFilters({ year: value });
            }}
            defaultValue={filters.year}
          />
          <Dropdown
            options={monthOptions}
            onSelect={(value) => {
              changeFilters({ month: value });
            }}
            defaultValue={filters.month}
          />
        </FiltersContainer>
        <CenterDiv className="flex-col space-y-2 rounded-2xl">
          {consumers ? (
            <Table data={consumers} columns={consumer_columns} onClickRow={null} />
          ) : (
            <span>Loading..</span>
          )}
        </CenterDiv>
      </FifthDiv>
      <SixthDiv>
        <Typography variant="h4" color="black">
          Banco de créditos
        </Typography>
        <FiltersContainer>
          {loadingConsumers && (
            <div className="my-auto h-6 w-6 animate-spin rounded-full border-4 border-solid border-black border-t-transparent pt-3" />
          )}
          <Dropdown
            options={yearsOptions}
            onSelect={(value) => {
              changeFilters({ year: value });
            }}
            defaultValue={filters.year}
          />
        </FiltersContainer>
        <CenterDiv className="flex-col space-y-2 rounded-2xl">
          {creditBalanceInfo ? (
            <Table
              data={
                creditBalanceInfo.filter((item: any) => item !== null) as typeof creditBalanceInfo
              }
              columns={creditBalanceColumns}
              onClickRow={null}
            />
          ) : (
            <span>Loading..</span>
          )}
        </CenterDiv>
      </SixthDiv>
      <Page>
        <FaqCardComponent variant="questionSection" />
      </Page>
      <Footer />
    </>
  );
};
export default InternalPowerPlants;
