import { router, usePage } from "@inertiajs/react";
import { useEffect, useState } from "react";

import FaqCardComponent from "@/components/Cards/FaqComponent";
import Layout from "@/components/Layout";

import DividendsGraphSection from "./sections/dividendsGraphSection";
import DocsTableSection from "./sections/docsTableSection";
import QuotasSection from "./sections/quotasSection";
import SummarySection from "./sections/summarySection";

export default function InvestorIndex({
  shares,
  events,
  all_share_quantity,
  all_share_values,
  last_dividend,
  graph_data,
  events_pagy,
  shares_pagy,
}) {
  const { currentUser }: any = usePage().props;
  const urlParams = new URLSearchParams(window.location.search);
  const [currentYear, setCurrentYear] = useState(
    urlParams.get("year") ? urlParams.get("year") : "Atual",
  );
  const [sharePage, setSharePage] = useState(shares_pagy.page);
  const maxSharesPages = shares_pagy.pages;
  const [eventPage, setEventPage] = useState(events_pagy.page);
  const maxEventPages = events_pagy.pages;

  const years = () => {
    const startYear = new Date(currentUser.created_at).getFullYear();
    const currentYear = new Date().getFullYear();
    return [
      ...Array.from({ length: currentYear - startYear + 1 }, (_, index) => startYear + index),
      "Atual",
    ];
  };

  const getEvents = (page) => {
    router.reload({
      only: ["events"],
      data: { event_page: page },
    });
  };

  const getShares = (page) => {
    router.reload({
      only: ["shares"],
      data: { share_page: page },
    });
  };

  useEffect(() => {
    if (currentYear === "Atual") {
      return router.reload({
        data: { year: null },
      });
    }

    router.reload({
      data: { year: currentYear },
    });
  }, [currentYear]);

  return (
    <Layout>
      <div className="flex w-full flex-col justify-center px-8 py-32 md:px-16">
        <SummarySection
          allShareQuantity={all_share_quantity}
          allShareValue={all_share_values}
          lastDividend={last_dividend}
          years={years()}
          currentYear={currentYear}
          setCurrentYear={setCurrentYear}
        />
        <QuotasSection
          shares={shares}
          page={sharePage}
          setPage={setSharePage}
          maxPages={maxSharesPages}
          getData={getShares}
        />
        <DividendsGraphSection
          data={graph_data}
          years={years()}
          currentYear={currentYear}
          setCurrentYear={setCurrentYear}
        />
        <DocsTableSection
          events={events}
          page={eventPage}
          setPage={setEventPage}
          maxPages={maxEventPages}
          getData={getEvents}
        />
        <div className="pt-12">
          <FaqCardComponent variant="questionSection" />
        </div>
      </div>
    </Layout>
  );
}
