const Pagination = ({ page, setPage, getData, maxPages }) => {
  return (
    <div className="my-6 flex items-center justify-center gap-2">
      <button
        className={`
          flex items-center rounded-lg px-3 py-2 text-sm font-medium text-white
          ${page === 1 ? "cursor-default bg-gray-400" : "bg-black hover:bg-gray-700"}
          transition-all duration-200 ease-in-out
        `}
        onClick={() => {
          if (page > 1) {
            setPage(1);
            getData(1);
          }
        }}
        disabled={page === 1}
      >
        <svg className="mr-1 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
          />
        </svg>
        <p className="hidden md:block">Primeiro</p>
      </button>

      <button
        className={`
          flex items-center rounded-lg px-3 py-2 text-sm font-medium text-white
          ${page === 1 ? "cursor-default bg-gray-400" : "bg-black hover:bg-gray-700"}
          transition-all duration-200 ease-in-out
        `}
        onClick={() => {
          if (page > 1) {
            setPage(page - 1);
            getData(page - 1);
          }
        }}
        disabled={page === 1}
      >
        <svg className="mr-1 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
        <p className="hidden md:block">Anterior</p>
      </button>

      <div className="flex items-center gap-1">
        {Array.from({ length: maxPages + 1 }, (_, i) => i).map((i) => {
          if (i === 0) return null;

          if (i === page) {
            return (
              <button
                key={i}
                className="rounded-lg bg-[#183F38] px-4 py-2 text-sm font-medium text-white hover:bg-green-800"
              >
                {i}
              </button>
            );
          }

          if (i >= page - 1 && i <= page + 1) {
            return (
              <button
                key={i}
                className="hidden rounded-lg bg-black px-4 py-2 text-sm font-medium text-white hover:bg-gray-700 md:block"
                onClick={() => {
                  setPage(i);
                  getData(i);
                }}
              >
                {i}
              </button>
            );
          }

          if (i === page - 2 || i === page + 2) {
            return (
              <span key={i} className="hidden px-2 text-gray-500 md:block dark:text-gray-400">
                ...
              </span>
            );
          }

          return null;
        })}
      </div>

      <button
        className={`
          flex items-center rounded-lg px-3 py-2 text-sm font-medium text-white
          ${page === maxPages ? "cursor-default bg-gray-400" : "bg-black hover:bg-gray-700"}
          transition-all duration-200 ease-in-out
        `}
        onClick={() => {
          if (page < maxPages) {
            setPage(page + 1);
            getData(page + 1);
          }
        }}
        disabled={page === maxPages}
      >
        <p className="hidden md:block">Próximo</p>
        <svg className="ml-1 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </button>

      <button
        className={`
          flex items-center rounded-lg px-3 py-2 text-sm font-medium text-white
          ${page === maxPages ? "cursor-default bg-gray-400" : "bg-black hover:bg-gray-700"}
          transition-all duration-200 ease-in-out
        `}
        onClick={() => {
          if (page < maxPages) {
            setPage(maxPages);
            getData(maxPages);
          }
        }}
        disabled={page === maxPages}
      >
        <p className="hidden md:block">Último</p>
        <svg className="ml-1 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M13 5l7 7-7 7M5 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
  );
};

export default Pagination;
